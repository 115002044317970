import { useQuery } from "@tanstack/react-query";
import { Container } from "components/common";
import { Layout } from "components/common/Layout";

import { Calendar } from "components/icons";
import { formatDate } from "utils";
import { countriesMap } from "constants";

const queryFn = () =>
  fetch(`/api/public/groups-ads-random`).then((res) => res.json());

const UserAvatars = ({ users }) => {
  const randomNumber = Math.floor(Math.random() * 35);

  if (!users || users.length === 0) return null;

  users.length = 4;

  return (
    <div className="flex items-center">
      <div className="flex -space-x-1">
        {users.map((user, index) => (
          <div
            key={user.image}
            className="w-6 h-6 rounded-full bg-gray-300 bg-cover bg-center ring-1 ring-white"
            style={{ backgroundImage: `url(${user.image})` }}
          />
        ))}
      </div>
      <div className="w-6 h-6 rounded-full bg-gray-200 ring-1 ring-white flex items-center justify-center text-[8px] font-medium text-gray-700 -ml-1">
        +{randomNumber}
      </div>
    </div>
  );
};

const GroupCard = ({
  groupId,
  title,
  membersCount,
  images,
  startDate,
  endDate,
  country,
  users,
}) => {
  return (
    <div className="rounded-lg bg-gray-50 overflow-hidden cursor-pointer">
      <div className="p-1">
        <div className="relative rounded-md overflow-hidden">
          <img
            src={images?.[0]}
            className="h-[120px] bg-gray-800 object-cover w-full"
          />
        </div>
        <div className="py-2 flex flex-col">
          <div className="font-semibold line-clamp-1 text-[13px] pb-[2px]">
            {title}
          </div>
          {countriesMap[country] ? (
            <div className="text-[10px] text-gray-600 line-clamp-1">
              {`${countriesMap[country].emoji} ${countriesMap[country].name}`}
            </div>
          ) : null}
          <div className="flex items-center gap-1 text-gray-600">
            <Calendar className="size-[10px] min-w-[10px]" />
            <div className="text-[10px]">{`${formatDate(
              startDate
            )} - ${formatDate(endDate)}`}</div>
          </div>
          <div className="pt-[2px]">
            <UserAvatars users={users} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Ads = () => {
  const { data } = useQuery({
    queryKey: ["GET_GROUP_ADS"],
    queryFn,
  });

  return (
    <div className="bg-black">
      <div className="min-h-[calc(100dvh)] flex flex-col">
        <div className="pt-[140px] py-[20px] text-white font-medium text-[30px] text-center px-14 leading-[40px]">
          trips that would fix all of my problems:
        </div>
        <div className="w-full">
          <div className="grid grid-cols-2 gap-2 py-4 px-4 relative bg-white">
            {(data?.data || []).map((el) => (
              <GroupCard key={el.groupId} {...el} />
            ))}
          </div>
          {/* <div className="w-[1000px] h-[200px] absolute bottom-0 left-0 bg-black"></div> */}
        </div>
      </div>
    </div>
  );
};
