import { useUser } from "hooks/useUser";
import { Login } from "pages/Login";
import { Logout } from "pages/Logout";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useOutlet,
  useNavigate,
} from "react-router-dom";
import { NotFound } from "./404";
import { Main } from "./Main";
import { Messages } from "./Messages";
import { Profile } from "./Profile";
import { Group } from "./Group";
import { Onboarding } from "./Onboarding";
import { useEffect } from "react";
import { GroupCreate } from "./GroupCreate";
import { GroupEdit } from "./GroupEdit";
import { Chat } from "./Chat";
import { Logo } from "components/icons";
import { motion, AnimatePresence } from "framer-motion";
import { SubscriptionSuccess } from "./Subscription/Success";
import { Pp } from "./General/Pp";
import { Tos } from "./General/Tos";
import { Bot } from "./Bot";
import { ProfileEdit } from "./ProfileEdit";
import { Search } from "./Search";
import { SearchList } from "./SearchList";
import { Ads } from "./Ads";

const LoaderRoute = () => {
  const navigate = useNavigate();
  const { isLoading: isLoadingUser, isAuth, userInfo } = useUser();

  useEffect(() => {
    if (isAuth && !userInfo?.isOnboarded) {
      navigate("/onboarding");
    }
  }, [userInfo?.isOnboarded, isAuth]);

  const outlet = useOutlet();

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/2">
        <AnimatePresence mode="wait">
          {isLoadingUser ? (
            <motion.div
              key="loader"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ duration: 0.5 }}
              className="w-full min-h-[calc(100dvh)] flex justify-center items-center"
            >
              <motion.div
                animate={{
                  scale: [1, 1.1, 1],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  repeatType: "loop",
                }}
              >
                <Logo className="w-[140px] h-[140px]" />
              </motion.div>
            </motion.div>
          ) : (
            <motion.div
              key="content"
              initial={{ opacity: 0, scale: 1.1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              {outlet}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="hidden md:block w-px bg-gray-200" />
      <div className="hidden relative md:flex w-full md:w-1/2">
        <div className="fixed top-1/2 left-3/4 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center p-4 gap-[10px]">
          <p className="text-center text-xl">Soon on</p>
          <img
            className="w-[200px]"
            src={"/img/apple-store.png"}
            alt="Apple Store"
          />
          <img
            className="w-[200px]"
            src={"/img/google-play.png"}
            alt="Google Play"
          />
        </div>
      </div>
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<LoaderRoute />}>
        <Route path="/" element={<Main />} />
        <Route path="/group/:groupId" element={<Group />} />
        <Route path="/group/create" element={<GroupCreate />} />
        <Route path="/group/edit/:groupId" element={<GroupEdit />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/chat/:groupId" element={<Chat />} />
        <Route path="/profile/:userId" element={<Profile />} />
        <Route path="/profile/edit" element={<ProfileEdit />} />
        <Route path="/login" element={<Login />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/search" element={<Search />} />
        <Route path="/search-list" element={<SearchList />} />
        <Route path="/subscription/success" element={<SubscriptionSuccess />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/pp" element={<Pp />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/ads" element={<Ads />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/bot" element={<Bot />} />
    </>
  )
);

export const Root = () => {
  return <RouterProvider router={router} />;
};
