import React, { useState } from "react";
import { Button, FullScreenModal } from "./common";
import { Check, Close, Minus } from "./icons";
import { config } from "constants";
import { useUser } from "hooks/useUser";

const featureList = [
  "Unlimited Group Join",
  "Hide Ads",
  "Group Description Links",
  "Chat Links",
  "Verified badge",
];

export const PremiumModal = ({ isOpen, onClose }) => {
  const [selected, setSelected] = useState("Y");
  const { userInfo } = useUser();

  const onSubmit = () => {
    const link = config?.payments?.[selected]?.link;
    if (link)
      window.location.href = `${link}?client_reference_id=${userInfo?.userId}`;
  };

  return (
    <FullScreenModal isOpen={isOpen} zoomType="out">
      <div className="bg-[#f9fafb] flex flex-col h-full">
        <div className="p-6 flex-shrink-0">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Go Premium</h2>
            <div
              onClick={onClose}
              className="bg-white border rounded-lg border-gray-200 p-1 cursor-pointer"
            >
              <Close className="size-[22px]" />
            </div>
          </div>
          <p className="text-gray-600">
            Best way to find more friends to travel together
          </p>
        </div>

        <div className="flex-grow overflow-y-auto px-6">
          <div className="bg-white border border-gray-200 p-3 rounded-lg mb-6">
            <div className="grid grid-cols-5 gap-1">
              <div className="col-span-3 pb-2 text-sm font-semibold text-gray-500 uppercase">
                What's included
              </div>
              <div className="col-span-1 pb-2 text-sm font-semibold text-gray-500 uppercase text-center">
                Free
              </div>
              <div className="col-span-1 pb-2 text-sm font-semibold text-acent uppercase text-center">
                Premium
              </div>

              {featureList.map((feature) => (
                <React.Fragment key={feature}>
                  <div className="col-span-3 py-1">{feature}</div>
                  <div className="col-span-1 flex justify-center items-center">
                    <Minus className="w-5 h-5 text-gray-400" />
                  </div>
                  <div className="col-span-1 flex justify-center items-center">
                    <Check className="w-5 h-5 text-acent" />
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="space-y-3 mb-6">
            {[
              {
                key: "M",
                duration: "1 Month",
                price: "$5",
              },
              {
                key: "Y",
                duration: "1 Year",
                price: "$4/month",
                perPeriod: "$50",
                popular: true,
              },
              {
                key: "F",
                duration: "Lifetime",
                price: "$90",
              },
            ].map((plan) => (
              <div
                key={plan.duration}
                onClick={() => setSelected(plan.key)}
                className={`p-3 rounded-lg border cursor-pointer ${
                  plan.key === selected
                    ? "border-acent bg-blue-50"
                    : "border-gray-200"
                }`}
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold">{plan.duration}</span>
                  {plan.popular && (
                    <span className="text-xs font-semibold text-acent bg-blue-100 px-2 py-1 rounded">
                      Popular
                    </span>
                  )}
                </div>
                <div className="text-acent font-bold">
                  {plan.price}{" "}
                  {plan.perPeriod ? (
                    <span className="text-gray-500 font-normal">
                      · {plan.perPeriod}
                    </span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="p-6 flex-shrink-0">
          <Button className="w-full" onClick={onSubmit}>
            Upgrade to Premium
          </Button>
        </div>
      </div>
    </FullScreenModal>
  );
};
