import { Button, Container, Selector, Textarea } from "components/common";
import { Layout, Input, PhotoInput, DateInput } from "components/common";
import { useState, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { countriesMap } from "constants";

const queryCreateGroup = (info) => {
  const formData = new FormData();
  formData.append("title", info.title);
  formData.append("description", info.description);
  formData.append("startDate", new Date(info.startDate).toISOString());
  formData.append("endDate", new Date(info.endDate).toISOString());
  formData.append("country", info.country);
  formData.append("photo", info.photo);

  return fetch("/api/private/group/create", {
    method: "POST",
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) {
        return res;
      } else {
        throw new Error(res.message);
      }
    });
};

export const GroupCreate = () => {
  const navigate = useNavigate();
  const countriesKeyList = Object.keys(countriesMap);
  const [info, setInfo] = useState({
    title: "",
    description: "",
    startDate: Date.now(),
    endDate: Date.now(),
    country: "",
    photo: null,
  });

  const { mutate: onSubmit, isLoading } = useMutation({
    mutationFn: () => queryCreateGroup(info),
    onSuccess: (res) => {
      if (res.success && res.data?.groupId) {
        navigate(`/group/${res.data?.groupId}?groupType=new`);
      }
    },
  });

  const onChangeInfo = useCallback(
    (field) => (value) => {
      setInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
    },
    []
  );

  const actionButton = (
    <Button isLoading={isLoading} className="w-full" onClick={() => onSubmit()}>
      Create
    </Button>
  );

  return (
    <Layout
      title="Create group"
      showBack
      showMenu={false}
      actionButtonSlot={actionButton}
      pageTitle="Create group"
    >
      <div className="w-full">
        <div className="relative">
          <PhotoInput
            id="photo"
            label="Upload photo"
            type="file"
            onChange={onChangeInfo("photo")}
          />
          <div className="h-[10px] absolute bottom-0 bg-white left-0 rounded-t-xl w-full" />
        </div>
        <Container>
          <div className="w-full pt-2">
            <div className="flex flex-col gap-[20px]">
              <Input
                label="Title"
                placeholder="Big trip to Olympic games"
                type="text"
                onChange={onChangeInfo("title")}
                value={info.title}
              />
              <div className="flex gap-4 w-full">
                <DateInput
                  label="Start Date"
                  value={info.startDate}
                  id="start-date"
                  onChange={onChangeInfo("startDate")}
                  minDate={new Date()}
                />
                <DateInput
                  label="End Date"
                  value={info.endDate}
                  id="end-date"
                  onChange={onChangeInfo("endDate")}
                  minDate={new Date()}
                />
              </div>
              <Textarea
                rows={10}
                label="Description"
                placeholder="Big trip to Olympic games"
                type="text"
                onChange={onChangeInfo("description")}
                value={info.description}
              />
              <Selector
                label="Country"
                value={info.country}
                onChange={onChangeInfo("country")}
                id="country-selector"
                options={countriesKeyList.map((countryKey) => ({
                  value: countryKey,
                  label: `${countriesMap[countryKey]?.emoji} ${countriesMap[countryKey]?.name}`,
                }))}
                placeholder="Choose country"
              />
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};
