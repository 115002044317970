import { useGoogleLogin } from "@react-oauth/google";
import { useUser } from "../../hooks/useUser";
import { useState } from "react";

const googleLogin = (credential, callback) =>
  fetch("/api/auth/google/login", {
    method: "POST",
    body: JSON.stringify(credential),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) {
        callback();
      } else {
        throw new Error(res.message);
      }
    });

export const GoogleSignInButton = () => {
  const { refetch } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      setLoading(true);
      setError(null);
      try {
        googleLogin({ code }, refetch);
      } catch (error) {
        console.error("Error during authentication:", error);
        setError("Authentication failed. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("Error during authentication:", error);
      setError("Google Sign-In was unsuccessful. Please try again.");
    },
    flow: "auth-code",
    scope: "openid profile email",
  });

  return (
    <div className="w-full">
      <button
        onClick={() => login()}
        className={`w-full bg-white border border-line rounded h-[60px] flex items-center justify-center gap-2 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
          loading ? "cursor-not-allowed opacity-50" : ""
        }`}
        disabled={loading}
      >
        {loading ? (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            {/* Circle Background */}
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            {/* Spinner Path */}
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
        ) : (
          // Google Icon
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 fill-current"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <script xmlns="" />
            <path
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              fill="#4285F4"
            />
            <path
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              fill="#34A853"
            />
            <path
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              fill="#FBBC05"
            />
            <path
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              fill="#EA4335"
            />
            <path d="M1 1h22v22H1z" fill="none" />
          </svg>
        )}
        <span>{loading ? "Signing in..." : "Sign in with Google"}</span>
      </button>
      {error && <p className="mt-4 text-red-500 text-sm">{error}</p>}
    </div>
  );
};
