import { useEffect, useState } from "react";
import { Button, Input, Layout } from "components/common";
import { useUser } from "hooks/useUser";
import { useMutation } from "@tanstack/react-query";
import { emailRegex } from "constants";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { GoogleSignInButton } from "../components/common/GoogleSignInButton";

const queryLoginWithEmail = (email) =>
  fetch("/api/auth/login", {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });

const getFacebookAuthLink = () =>
  fetch("/api/auth/facebook", {
    method: "GET",
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success && res.redirect) {
        window.location.href = res.redirect;
      } else {
        throw new Error(res.message);
      }
    });

const googleLogin = (credential, callback) =>
  fetch("/api/auth/google/login", {
    method: "POST",
    body: JSON.stringify(credential),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) {
        callback();
      } else {
        throw new Error(res.message);
      }
    });

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { refetch: refetchUserInfo, isAuth, userInfo } = useUser();

  const {
    data: emailLoginData,
    mutate: onSendEmail,
    isLoading,
  } = useMutation({
    mutationFn: async (email) => {
      if (!email?.match(emailRegex)) {
        throw new Error("Wrong email");
      }
      return queryLoginWithEmail(email);
    },
    onSuccess: () => {
      refetchUserInfo();
    },
  });

  useEffect(() => {
    if (isAuth) {
      if (!userInfo?.isOnboarded) {
        navigate("/onboarding");
      } else {
        navigate("/");
      }
    }
  }, [isAuth, navigate, userInfo?.isOnboarded]);

  if (emailLoginData?.success)
    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full min-h-[calc(100dvh)] flex justify-center items-center"
      >
        <div className="w-full max-w-[400px] px-2">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
            className="text-2xl text-main font-bold text-center mb-4"
          >
            Check your email 📨
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="text-xl text-main text-center"
          >
            A sign in link has been sent to your email address
          </motion.div>
        </div>
      </motion.div>
    );

  return (
    <Layout showMenu={false} pageTitle="Login">
      <div className="w-full px-2 flex flex-col justify-center">
        <div className="text-2xl font-bold text-center mb-4">Sign In</div>
        <div className="w-full">
          <div className="flex flex-col gap-2 justify-center w-full">
            <GoogleSignInButton />
            <div
              data-umami-event="Sign in With Facebook Button"
              onClick={getFacebookAuthLink}
              className="w-full bg-[#1877F2] hover:bg-[#166fe5] text-white font-semibold py-2 px-4 rounded h-[60px] flex items-center justify-center gap-2 transition duration-300 ease-in-out cursor-pointer"
            >
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
              </svg>
              Sign in with Facebook
            </div>
          </div>
        </div>
        <div className="relative flex py-4 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 text-gray-400 text-xs">OR</span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <div>
          <Input
            label="Email"
            placeholder="you@email.com"
            type="email"
            onChange={setEmail}
            onEnter
          />
          <Button
            data-umami-event="Sign in With Email Button"
            isLoading={isLoading}
            className="w-full h-[40px] mt-2 bg-gray-700"
            onClick={() => onSendEmail(email)}
            disabled={isLoading}
          >
            Sign in with Email
          </Button>
          <div className="text-sm text-center pt-2 text-gray-500 max-w-[300px] mx-auto">
            By creating a Talivia account, you agree to our{" "}
            <Link className="underline text-main" to="/tos">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link className="underline text-main" to="/pp">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};
