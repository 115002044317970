import { useCallback, useEffect, useState } from "react";
import { Button, Container, Input, Layout } from "components/common";
import { useUser } from "hooks/useUser";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { PhotoInput } from "components/common/PhotoInput";
import { DateInput } from "components/common/DateInput";
import Confetti from "react-confetti";

const DEFAULT_BIRTHDATE = new Date(
  new Date().setFullYear(new Date().getFullYear() - 18)
).getTime();

const fetchOnboarding = (info) => {
  const formData = new FormData();
  const birthdate = info.birthdate
    ? new Date(info.birthdate).toISOString()
    : undefined;
  formData.append("name", info.name);
  formData.append("birthdate", birthdate);
  formData.append("photo", info.photo);

  return fetch("/api/private/user/onboarding", {
    method: "POST",
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res;
      throw new Error(res.message);
    });
};

export const Onboarding = () => {
  const navigate = useNavigate();
  const { refetch, isAuth, userInfo } = useUser();
  const [showConfetti, setShowConfetti] = useState(true);

  const [info, setInfo] = useState({
    birthdate: userInfo?.birthdate ?? DEFAULT_BIRTHDATE,
    name: userInfo?.name || "",
    photo: userInfo?.images?.[0] || null,
  });

  const { data, mutate: onSubmit } = useMutation({
    mutationFn: () => fetchOnboarding(info),
    onSuccess: () => {
      refetch();
      setShowConfetti(true);
    },
  });

  const onChangeInfo = useCallback(
    (field) => (value) => {
      setInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
    },
    []
  );

  useEffect(() => {
    if (!isAuth) navigate("/login");
  }, [isAuth, navigate, userInfo?.isOnboarded]);

  const bottomSlot = (
    <Button className="w-full" onClick={() => onSubmit()}>
      Save
    </Button>
  );

  if (data?.success)
    return (
      <div className="w-full min-h-[calc(100dvh)] flex justify-center items-center relative flex-col">
        {showConfetti && <Confetti recycle={false} numberOfPieces={200} />}
        <div className="w-full max-w-[400px] px-2">
          <div className="text-2xl text-main font-bold text-center mb-4">
            Congrats 🎉
          </div>
          <div className="text-xl text-main text-center">
            You finished onboarding process
          </div>
        </div>
        <div className="absolute bottom-4 px-2 w-full">
          <Button
            onClick={() => {
              const returnTo = window.localStorage.getItem("returnTo");
              window.localStorage.removeItem("returnTo");
              returnTo ? navigate(atob(returnTo)) : navigate("/");
            }}
            className="w-full"
          >
            Find travel group!
          </Button>
        </div>
      </div>
    );

  return (
    <Layout
      showMenu={false}
      actionButtonSlot={bottomSlot}
      pageTitle="Onboarding"
    >
      <div className="w-full">
        <div className="relative w-full">
          <PhotoInput
            id="photo"
            label="Upload photo"
            type="file"
            onChange={onChangeInfo("photo")}
            value={info.photo}
          />
          <div className="absolute inset-x-0 bottom-0 h-[50%] bg-gradient-to-t from-black/80 to-transparent z-10 pointer-events-none"></div>
        </div>
        <Container className="relative -mt-10 bg-white rounded-t-[30px] px-4 z-20">
          <div className="w-full flex justify-center py-2">
            <div className="w-20 h-1 bg-gray-300 rounded-full" />
          </div>
          <div className="w-full pt-2 flex flex-col gap-4">
            <Input
              label="Name"
              placeholder="Josh"
              type="text"
              onChange={onChangeInfo("name")}
              value={info.name}
            />
            <DateInput
              label="Birthdate"
              value={info.birthdate}
              id="birthdate-123"
              onChange={onChangeInfo("birthdate")}
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear() - 18))
              }
            />
          </div>
        </Container>
      </div>
    </Layout>
  );
};
